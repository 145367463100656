import { IUser, IWixAPI } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID } from '../constants';

export const createOnLoginHandlerWithTB8465Workaround = (wixCodeApi: IWixAPI) => (onLogin: (user: IUser) => void) => {
  let p1Resolve: () => void, p2Resolve: () => void;
  const p1 = new Promise<void>((resolve) => (p1Resolve = resolve));
  const p2 = new Promise<void>((resolve) => (p2Resolve = resolve));

  wixCodeApi.site.onInstanceChanged(() => p1Resolve(), PRICING_PLANS_APP_DEF_ID);
  wixCodeApi.user.onLogin(() => p2Resolve());

  Promise.all([p1, p2]).then(() => onLogin(wixCodeApi.user.currentUser));
};
